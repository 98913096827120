import { dimensionRoutes } from '@/routes/dimension'
import { budgetRoutes } from '@/routes/budget'
import { recommandationRoutes } from '@/routes/recommandation'

export const viewerRoutes = [
  {
    path: '/',
    component: () => import('@/layouts/ViewersLayout.vue'),
    children: [
      {
        path: '',
        redirect: '/viewer-dashboard',
      },
      {
        name: 'dashboard',
        path: '/viewer-dashboard',
        component: () => import('@/views/viewers/DashboardView.vue'),
      },
      ...dimensionRoutes,
      ...budgetRoutes,
      ...recommandationRoutes,
    ],
  },
]
