export const guestRoutes = [
  {
    name: 'loginSuccess',
    path: '/loginsuccess',
    component: () => import('@/views/LoginSuccess.vue'),
    meta: { menu: false, navbar: false, noAuth: true },
  },
  {
    path: '/login',
    component: () => import('@/views/LoginSession.vue'),
    meta: { menu: false, navbar: false, noAuth: true },
    children: [
      {
        name: 'login',
        path: '',
        component: () => import('@/components/layouts/login/BlocLogin.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      {
        name: 'blocEmailValidation',
        path: 'emailValidation',
        component: () =>
          import('@/components/layouts/login/BlocEmailValidation.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      {
        name: 'blocPasswordForgotten',
        path: 'passwordForgotten',
        component: () =>
          import('@/components/layouts/login/BlocPasswordForgotten.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      {
        name: 'blocPasswordRecoverySuccess',
        path: 'passwordRecoverySuccess',
        component: () =>
          import('@/components/layouts/login/BlocPasswordRecoverySuccess.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
      {
        name: 'blocPasswordRecoveryError',
        path: 'passwordRecoveryError',
        component: () =>
          import('@/components/layouts/login/BlocPasswordError.vue'),
        meta: { menu: false, navbar: false, noAuth: true },
      },
    ],
  },
]
