module.exports = {
  welcomeTitle: 'Bonjour',
  welcomeSubtitle: 'Que voulez-vous faire aujourd’hui ?',
  pinModal: {
    title: 'Sélectionner une dimension',
    subText:
      'Votre dimension sera sélectionnée par défaut à votre prochaine connexion.',
    noDataTitle: 'Aucune dimension trouvée.',
    noDataSubTitle: 'Merci de contacter votre administrateur',
  },
  editUserModal: {
    title: 'Mon profil',
    subTitle: `Configurez votre profil d'utilisateur`,
    gravatar: 'Personnaliser mon gravatar',
    inputFirstName: 'Prénom',
    inputLastName: 'Nom',
    inputEmail: 'Email',
    selectLangue: 'Langue',
  },
  editLogoModal: {
    tooltip: 'Ajoutez votre logo',
  },
  dimension: {
    pin: 'Épinglée',
  },
  granularity: {
    lastThirtyDay: 'Les 30 derniers jours',
    lastMonth: 'Le mois dernier',
    thisMonth: 'Le mois en cours',
    startYear: `L’année en cours`,
    label: 'Période',
  },
  charts: {
    btn: 'Sélectionnez une dimension',
    noData: 'Aucun fournisseur n’est sélectionné.',
    values: 'Valeurs',
  },
  kpi: {
    lastThirtyDay: '30 derniers jours',
    lastMonth: 'Mois dernier',
    thisMonth: 'Mois en cours',
    startYear: 'Année en cours',
  },
  sectionsTitles: {
    expensesBySupplierTitle: 'Dépenses par fournisseur',
    expensesBySupplierSubTitle: 'Les montants sont affichés en',
    expensesBySupplierSeparator: 'et par',
    expensesBySupplierDay: 'JOURS',
    expensesBySupplierMonth: 'MOIS',
    dimensions: 'Sous-dimensions de',
  },
  childDimension: {
    cost: 'Coût total',
    budget: 'Ajouter un budget',
    search: 'Rechercher une dimension',
    filterTitle: 'Filtrer les dimensions',
    filterSelectedTitleSingular: 'Filtre sélectionné',
    filterSelectedTitlePlural: 'Filtres selectionnés',
    filterOptionsOne: 'Récemment consultées',
    filterOptionsTwo: 'Coûts croissants',
    filterOptionsThree: 'Coûts décroissants',
    noChild: 'Aucune dimension enfant',
    noResult: 'Aucun résultat',
    trend: 'Tendance',
    granularityInfo: 'Par rapport à la période précédente',
  },
}
