import { updateLight } from '@/plugins/vuetify'
import ThemesService from '@/services/themes'

const state = () => ({
  id: null,
  name: '',
  billing_address: '',
  phone: '',
  currency: localStorage.getItem('currency') || 'EUR',
  importStatus: 'done',
  logo: '',
  theme: {
    backgroundColor: '#FBFBFB',
    primary: '#2787C3',
    secondary: '#8EC142',
  },
})

const getters = {
  company: (state) => {
    return state
  },
  status: (state) => {
    return state.importStatus
  },
  logo: (state) => {
    return state.logo
  },
  theme: (state) => {
    return state.theme
  },
}

const mutations = {
  setCompanyInfo: (state, payload) => {
    state.name = payload.name
    state.billing_address = payload.billing_address
    state.phone = payload.phone
    state.currency = payload.currency
  },
  setCurrency: (state, payload) => {
    state.currency = payload
  },
  SET_IMPORT_STATUS: (state, payload) => {
    state.importStatus = payload
  },
  SET_LOGO: (state, payload) => {
    state.logo = payload
  },
  SET_THEME: (state, payload) => {
    state.theme = payload
  },
}

const actions = {
  setImportStatus({ commit }, payload) {
    commit('SET_IMPORT_STATUS', payload)
  },
  async getTheme({ commit, rootState }) {
    const service = new ThemesService()
    const theme = await service.getDefaultTheme(rootState.user.company_id)
    if (theme && theme.length > 0) {
      commit('SET_THEME', theme[0])
      commit('SET_LOGO', theme[0].logo)
      return updateLight()
    }
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
