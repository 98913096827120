import store from '@/store'

const locale = store.getters.user.lang || 'fr'

export function useCostFormatter(cost, maxDec = 2, originalCur = null) {
  if (cost === null) {
    return '--'
  }

  // make sure that small costs are displayed with 4 decimals
  if (maxDec === 2 && cost < 0.1) {
    maxDec = 4
  }
  const currency = originalCur || store.getters.company.currency

  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: maxDec || 0,
    minimumFractionDigits: maxDec,
  }).format(cost || 0)
}

export function useCostReducer(data, column) {
  return data.reduce((acc, cur) => {
    if (typeof cur[column] === 'string') {
      return Math.round((acc += parseFloat(cur[column].replace(/,/g, '.'))))
    }
    return Math.round((acc += cur[column]))
  }, 0)
}

export function useAllPeriodsCost(periods, isReforecast = false) {
  return periods.reduce((acc, cur) => {
    const period = isReforecast ? cur.reforecast : cur.allocated_cost
    return acc.concat(parseFloat(period))
  }, [])
}
