export const dimensionRoutes = [
  {
    path: '/dimensions',
    component: () => import('@/views/dimensions/DimensionsLayout.vue'),
    children: [
      {
        name: 'dimensions',
        path: '',
        meta: { permission: 'dimension.index', drawerType: 'dimensions' },
        component: () => import('@/views/dimensions/DimensionsIndexView.vue'),
      },
      {
        name: 'dimensions-type',
        path: ':type',
        meta: { permission: 'dimension.index', drawerType: 'dimensions' },
        component: () => import('@/views/dimensions/DimensionsView.vue'),
      },
      {
        name: 'dimension',
        path: ':type/:id',
        meta: { permission: 'dimension.index' },
        component: () => import('@/views/dimensions/DimensionView.vue'),
        children: [
          {
            name: 'dimension-overview',
            path: 'overview',
            meta: {
              permission: 'dimension.index',
              drawerType: 'dimension',
            },
            component: () =>
              import('@/components/dimensions/DimensionOverview.vue'),
          },
          {
            name: 'dimension-subdimensions',
            path: 'sub-dimensions',
            meta: {
              permission: 'dimension.index',
              drawerType: 'dimension',
            },
            component: () =>
              import('@/components/dimensions/DimensionSubDimensions.vue'),
          },
          {
            name: 'dimension-explorers',
            path: 'explorers',
            meta: {
              permission: 'dimension.index',
              drawerType: 'dimension',
              color: 'primary',
            },
            component: () =>
              import('@/components/dimensions/DimensionExplorer.vue'),
          },
          {
            name: 'dimension-budgets',
            path: 'budgets',
            meta: {
              permission: 'dimension.index',
              drawerType: 'dimension',
            },
            component: () =>
              import('@/components/dimensions/DimensionBudgets.vue'),
          },
          {
            name: 'dimension-budget-create',
            path: 'budget/create',
            meta: { permission: 'budget.store' },
            component: () => import('@/views/BudgetForm'),
          },
          {
            name: 'dimension-alerts',
            path: 'alerts',
            meta: {
              permission: 'dimension.index',
              drawerType: 'dimension',
            },
            component: () =>
              import('@/components/dimensions/DimensionAlerts.vue'),
          },
          {
            name: 'dimension-filters',
            path: 'filters',
            meta: {
              permission: 'dimension.index',
              drawerType: 'dimension',
            },
            component: () =>
              import('@/components/dimensions/DimensionFilters.vue'),
          },
          {
            name: 'dimension-users',
            path: 'users',
            meta: {
              permission: 'dimension.index',
              drawerType: 'dimension',
            },
            component: () =>
              import('@/components/dimensions/DimensionUsers.vue'),
          },
          {
            name: 'dimension-delete',
            path: 'delete',
            meta: {
              permission: 'dimension.destroy',
              drawerType: 'dimension',
            },
            component: () =>
              import('@/components/dimensions/DimensionEdit.vue'),
          },
        ],
      },
    ],
  },
]
