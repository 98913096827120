<template>
  <v-container fluid>
    <v-row v-if="!hideHeader" align="center" no-gutters>
      <slot name="header">
        <v-col cols="12">
          <v-card color="transparent" flat>
            <v-card-title class="subtitle-1">
              {{ $lang.recommendations.summaryPotentialSavings }}
            </v-card-title>
          </v-card>
          <v-divider></v-divider>
        </v-col>
        <v-spacer />
        <v-col cols="6" md="4">
          <v-card color="transparent" flat>
            <v-card-title class="subtitle-1">
              {{ $lang.recommendations.potentialEarns }}
            </v-card-title>
            <v-card-subtitle class="py-o">
              {{ $lang.recommendations.notAppliedRecommendations }}
            </v-card-subtitle>
            <v-card-title class="pt-0">
              <div>
                <div class="subtitle-2">{{ $lang.optimization.perMonth }}</div>
                <div class="primary--text subtitle-1 font-weight-bold">
                  {{ useCostFormatter(allPotentialSavings) }}
                </div>
              </div>
              <v-spacer />
              <div class="text-center">
                <div class="subtitle-2">
                  {{ $lang.optimization.perSemester }}
                </div>
                <div class="primary--text subtitle-1 font-weight-bold">
                  {{ useCostFormatter(allPotentialSavings * 6) }}
                </div>
              </div>
              <v-spacer />
              <div class="text-right">
                <div class="subtitle-2">{{ $lang.optimization.perYear }}</div>
                <div class="primary--text subtitle-1 font-weight-bold">
                  {{ useCostFormatter(allPotentialSavings * 12) }}
                </div>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
        <v-divider vertical />
        <v-col cols="6" md="4">
          <v-card color="transparent" flat>
            <v-card-title class="subtitle-1">
              {{ $lang.recommendations.finalEarns }}
            </v-card-title>
            <v-card-subtitle class="py-o">
              {{ $lang.recommendations.verifiedRecommendations }}
            </v-card-subtitle>
            <v-card-title class="pt-0">
              <div>
                <div class="subtitle-2">{{ $lang.optimization.perMonth }}</div>
                <div class="primary--text subtitle-1 font-weight-bold">
                  {{ useCostFormatter(allFinalSavings) }}
                </div>
              </div>
              <v-spacer />
              <div class="text-center">
                <div class="subtitle-2">
                  {{ $lang.optimization.perSemester }}
                </div>
                <div class="primary--text subtitle-1 font-weight-bold">
                  {{ useCostFormatter(allFinalSavings * 6) }}
                </div>
              </div>
              <v-spacer />
              <div class="text-right">
                <div class="subtitle-2">{{ $lang.optimization.perYear }}</div>
                <div class="primary--text subtitle-1 font-weight-bold">
                  {{ useCostFormatter(allFinalSavings * 12) }}
                </div>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
        <v-divider vertical />
        <v-col cols="6" md="4">
          <v-card color="transparent" flat>
            <v-card-title class="py-0 text-center">
              <div class="subtitle-2 font-weight-bold">
                {{ $lang.recommendations.summary }}
              </div>
            </v-card-title>
            <v-card-title class="py-0 text-center">
              <div class="subtitle-2">
                {{ $lang.recommendations.currentlyRunning }}
              </div>
              <v-spacer />
              <div class="subtitle-1">
                <span v-if="recommendations.length" class="pr-3 primary--text">
                  {{
                    `${(
                      (getCountByStatus('running') / recommendations.length) *
                      100
                    ).toFixed()} %`
                  }}
                </span>
                <span>
                  {{ getCountByStatus('running') }} /
                  {{ recommendations.length }}
                </span>
              </div>
            </v-card-title>
            <v-card-title class="py-0 text-center">
              <div class="subtitle-2">
                {{ $lang.recommendations.appliedRecommendations }}
              </div>
              <v-spacer />
              <div class="subtitle-1">
                <span v-if="recommendations.length" class="pr-3 primary--text">
                  {{
                    `${(
                      (getCountByStatus('applied') / recommendations.length) *
                      100
                    ).toFixed()} %`
                  }}
                </span>
                <span>
                  {{ getCountByStatus('applied') }} /
                  {{ recommendations.length }}
                </span>
              </div>
            </v-card-title>
            <v-card-title class="py-0 text-center">
              <div class="subtitle-2">
                {{ $lang.recommendations.checkedRecommendations }}
              </div>
              <v-spacer />
              <div class="subtitle-1">
                <span v-if="recommendations.length" class="pr-3 primary--text">
                  {{
                    `${(
                      (getCountByStatus('checked') / recommendations.length) *
                      100
                    ).toFixed()} %`
                  }}
                </span>
                <span>
                  {{ getCountByStatus('checked') }} /
                  {{ recommendations.length }}
                </span>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
          <v-card class="pb-0" color="transparent" flat>
            <v-card-title>
              {{ $lang.recommendations.list }}
              <v-spacer />
              <v-text-field
                v-model="search"
                :label="$lang.tags.searchPlaceHolder"
                append-icon="mdi-magnify"
                color="grey darken-3"
                dense
                hide-details
                outlined
                rounded
                style="max-width: 300px"
              ></v-text-field>
            </v-card-title>
          </v-card>
        </v-col>
      </slot>
    </v-row>
    <v-divider />
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :height="$vuetify.breakpoint.height - 450"
          :items="recommendations"
          :items-per-page="-1"
          :loading="loading"
          :search="search"
          :show-select="showSelect"
          class="row-pointer transparent"
          fixed-header
          hide-default-footer
          @click:row="handleClickRow"
        >
          <!-- <template #[`header.data-table-select`] hidden></template> -->
          <template #[`item.data-table-select`]="{ item }">
            <slot :item="item" name="select">
              <v-checkbox
                :key="item.id"
                v-model="item.selected"
                :disabled="item.disabled"
                :label="item.name"
                :value="item.id"
              ></v-checkbox>
            </slot>
          </template>
          <template #[`item.author`]="{ item }">
            <div v-if="item.author" class="d-flex flex-column align-center">
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <v-avatar size="30" v-bind="attrs" v-on="on">
                    <v-gravatar :email="item.author.email"></v-gravatar>
                  </v-avatar>
                </template>
                {{ `${item.author.firstname} ${item.author.lastname}` }}
              </v-tooltip>
            </div>
          </template>
          <template #[`item.assignee`]="{ item }">
            <div v-if="item.assignee" class="d-flex flex-column align-center">
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <v-avatar size="30" v-bind="attrs" v-on="on">
                    <v-gravatar :email="item.assignee.email"></v-gravatar>
                  </v-avatar>
                </template>
                {{ `${item.assignee.firstname} ${item.assignee.lastname}` }}
              </v-tooltip>
            </div>
          </template>
          <template #loading>
            <v-card
              :height="$vuetify.breakpoint.height - 600"
              class="d-flex flex-column justify-center align-center"
              flat
              tile
            >
              <v-progress-circular
                class="centerCard"
                color="primary"
                indeterminate
                size="80"
                width="8"
              ></v-progress-circular>
              <div class="pa-4">
                {{ $lang.tags.loadingText }}
              </div>
            </v-card>
          </template>
          <template #[`item.recommendation_category`]="{ item }">
            {{
              $lang.recommendationCategory[
                item.recommendation_category['shortname']
              ] || item.recommendation_category['shortname']
            }}
          </template>
          <template #[`item.due_at`]="{ item }">
            {{ item.due_at | localeDateString }}
          </template>
          <template #[`item.potential_savings`]="{ item }">
            {{ useCostFormatter(item.potential_savings) }}&nbsp;/&nbsp;{{
              $lang.optimization.month
            }}
          </template>
          <template #[`item.final_savings`]="{ item }">
            {{ useCostFormatter(item.final_savings) }}&nbsp;/&nbsp;{{
              $lang.optimization.month
            }}
          </template>
          <template #[`item.description`]="{ item }">
            {{ truncateDescription(item.description) }}
          </template>
          ,
          <template #[`item.priority`]="{ item }">
            {{ $lang.recommendations[item.priority] }}
          </template>
          <template #[`item.impact`]="{ item }">
            {{ $lang.recommendations[item.impact] }}
          </template>
          <template #[`item.status`]="{ item }">
            {{ $lang.recommendations[item.status] }}
          </template>
          <template #[`item.actions`]="{ item }">
            <slot v-if="!hideActions" :item="item" name="custom-action">
              <v-menu offset-x>
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense flat>
                  <v-list-item
                    v-for="action in actions"
                    :key="action.title"
                    @click.stop="action.onClick(item)"
                  >
                    <v-list-item-subtitle>
                      {{ $lang.recommendations[action.title] }}
                    </v-list-item-subtitle>
                    <v-icon :color="action.color" class="pl-2" dark>
                      {{ action.icon }}
                    </v-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </slot>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import {
  ref,
  computed,
  getCurrentInstance,
  defineProps,
  defineEmits,
  toRefs,
} from 'vue'

import { useCostFormatter } from '@/composables/cost'

const instance = getCurrentInstance().proxy
const $lang = computed(() => instance.$lang)
const $router = computed(() => instance.$router)
const $store = computed(() => instance.$store)

const props = defineProps({
  recommendations: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  hideHeader: {
    type: Boolean,
    default: false,
  },
  disableClickRow: {
    type: Boolean,
    default: false,
  },
  showSelect: {
    type: Boolean,
    default: false,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  dense: {
    type: Boolean,
    default: false,
  },
})

const { recommendations, disableClickRow } = toRefs(props)

const emit = defineEmits(['confirm-delete', 'open-attachment-dialog'])

const search = ref('')
const headers = [
  {
    text: $lang.value.recommendations.assignee,
    value: 'assignee',
    align: 'center',
    sortable: false,
  },
  { text: $lang.value.recommendations.status, value: 'status' },
  { text: $lang.value.recommendations.priority, value: 'priority' },
  { text: $lang.value.recommendations.impact, value: 'impact' },
  {
    text: $lang.value.recommendations.category,
    value: 'recommendation_category',
  },
  {
    text: $lang.value.recommendations.estimateSaving,
    value: 'potential_savings',
  },
  {
    text: $lang.value.recommendations.finalSavings,
    value: 'final_savings',
  },
  { text: $lang.value.recommendations.dueDate, value: 'due_at' },
  {
    text: $lang.value.recommendations.author,
    value: 'author',
    align: 'center',
    sortable: false,
  },
  { text: '', value: 'actions' },
]

const actions = ref([
  {
    title: 'attach',
    icon: 'mdi-plus',
    color: 'primary',
    onClick: (item) => emit('open-attachment-dialog', item.id),
  },
  {
    title: 'delete',
    icon: 'mdi-close',
    color: 'red',
    onClick: (item) => emit('confirm-delete', item.id),
  },
])

function getCountByStatus(status) {
  return (
    (!!recommendations.value.length &&
      recommendations.value.filter((e) => e.status === status).length) ||
    0
  )
}

function handleClickRow(item) {
  if (!disableClickRow.value) {
    $router.value.push({
      name: 'recommendation',
      params: { id: item.id },
    })
  }
}

function truncateDescription(description) {
  return description && description.length > 50
    ? `${description.substring(0, 50)}...`
    : description
}

const { company, hasPermission, startDate, endDate } = $store.value.getters

const allPotentialSavings = computed(() => {
  return (
    (!!recommendations.value.length &&
      recommendations.value
        .filter((e) => e.status !== 'checked')
        .reduce((acc, cur) => {
          return (acc += cur.potential_savings)
        }, 0)) ||
    0
  )
})

const allFinalSavings = computed(() => {
  return (
    (!!recommendations.value.length &&
      recommendations.value
        .filter((e) => e.status === 'checked')
        .reduce((acc, cur) => {
          return (acc += cur.final_savings)
        }, 0)) ||
    0
  )
})
</script>
<style scoped>
.row-pointer:hover {
  cursor: pointer;
}
</style>
