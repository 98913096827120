import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { routeStrategy } from '@/routes/roleRoutes/routesStrategy'

Vue.use(VueRouter)

const routerRole = store.getters.routerRole
const roleRoutes = routeStrategy[routerRole].routes

const routes = [
  ...roleRoutes,
  {
    name: 'error',
    path: '*',
    component: () => import('../views/Error.vue'),
    meta: { menu: false, navbar: false },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const isAuth = store.getters.isAuth
  isAuth && authRedirect(to, from, next)
  !isAuth && noAuthRedirect(to, from, next)
})

const authRedirect = (to, from, next) => {
  if (routeStrategy.guest.routes.some((route) => route.path === to.path)) {
    next({ name: 'dashboard' })
  }
  next()
}
const noAuthRedirect = (to, from, next) => {
  if (!routeStrategy.guest.routes.some((route) => route.path === to.path)) {
    next({ path: '/login' })
  }
  next()
}

export function setRoleRoutes() {
  const currentRouterRole = store.getters.routerRole
  const currentRoleRoutes = routeStrategy[currentRouterRole].routes
  router.addRoutes(currentRoleRoutes)
}

export default router
