<template>
  <v-app-bar
    v-if="false !== $route.meta.menu"
    :loading="loading"
    app
    class="py-0"
    flat
    height="64"
    outlined
  >
    <v-app-bar-nav-icon @click.stop="setDrawer(!drawer)"></v-app-bar-nav-icon>

    <div class="font-mangueira">
      {{ title }}
      <v-chip
        v-if="route.meta.deprecated"
        class="ml-2"
        color="red"
        small
        text-color="white"
      >
        {{ $lang.catalog.deprecated }}
      </v-chip>
    </div>

    <v-spacer />

    <v-divider vertical />
    <v-expand-transition>
      <div
        v-if="
          (budgetsForRenewal.length || budgetsForUpdate.length) &&
          route.name !== 'budget-renewal' &&
          route.name !== 'budget-update'
        "
        class="px-4"
        style="width: 230px"
      >
        <v-menu nudge-bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark depressed style="width: 100%" v-on="on">
              <span class="text-overline pl-2">
                {{ $lang.menu.currentActions }}
              </span>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="budgetsForRenewal.length">
              <v-list-item-title
                class="editable"
                style="cursor: pointer; text-align: left"
                @click="redirection('renewal')"
              >
                {{
                  `${budgetsForRenewal.length}
                budget${budgetsForRenewal.length > 1 ? 's' : ''} ${
                    $lang.menu.waitingForRenewal
                  }`
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="budgetsForUpdate.length">
              <v-list-item-title
                class="editable"
                style="cursor: pointer; text-align: left"
                @click="redirection('update')"
              >
                {{
                  `${budgetsForUpdate.length}
                budget${budgetsForUpdate.length > 1 ? 's' : ''} ${
                    $lang.menu.waitingForUpdate
                  }`
                }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                class="editable"
                style="text-align: left"
                @click="reset"
              >
                {{ $lang.menu.cancelCurrentActions }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-expand-transition>

    <v-divider vertical />
    <!-- show user info (name firstname, avatar, admin) -->
    <div id="user-display" class="px-4 d-none d-md-block">
      <span
        v-if="user.firstname && user.firstname"
        class="font-weight-light font-mangueira-m"
      >
        {{ `${user.firstname} ${user.lastname}` }}
      </span>

      <br />

      <small v-if="user.company">{{ user.company }}</small>
    </div>

    <v-avatar size="45">
      <v-gravatar :email="user.email"></v-gravatar>
    </v-avatar>

    <v-menu
      attach="#user-display"
      close-delay="400"
      content-class="elevation-0"
      left
      nudge-bottom="15"
      offset-y
      open-on-hover
    >
      <template #activator="{ on }">
        <v-btn :retain-focus-on-click="false" icon small v-on="on">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-card flat outlined tile width="380">
        <v-list class="py-0">
          <v-list-item
            :disabled="$route.path === '/settings/profile'"
            link
            @click="$router.push({ path: '/settings/profile' })"
          >
            <v-list-item-avatar>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="font-weight-light">
              {{ $lang.card.editMyProfile }}
            </v-list-item-title>
          </v-list-item>

          <v-divider />

          <template v-if="hasPermission('switch.company')">
            <v-list-item link @click="changeCompany = true">
              <v-list-item-avatar>
                <v-icon>mdi-swap-horizontal</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="font-weight-light">
                {{ $lang.company.changeCompany }}
              </v-list-item-title>
            </v-list-item>

            <v-divider />
          </template>

          <v-list-item class="red--text" link @click="useSessionClear">
            <v-list-item-avatar>
              <v-icon color="red">mdi-logout</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="font-weight-light">
              {{ $lang.card.logout }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <CompanyDialog v-model="changeCompany" />
  </v-app-bar>
</template>

<script setup>
import { ref, computed, defineProps, getCurrentInstance } from 'vue'
import CompanyDialog from '@/components/shared/CompanyDialog'
import { useRoute, useRouter } from 'vue-router/composables'
import { useSessionClear } from '@/composables/session'

const instance = getCurrentInstance().proxy
const $store = computed(() => instance.$store)
const $route = computed(() => instance.$route)
const router = useRouter()
const route = useRoute()

const props = defineProps({ title: { type: String, default: 'Lota.cloud' } })

const changeCompany = ref(false)
const loading = ref(false)
const newBudget = computed(() => $store.value.getters.getNewBudget)
const budgetsForRenewal = computed(() => {
  return newBudget.value.filter((b) => b.renewal === true)
})
const budgetsForUpdate = computed(() => {
  return newBudget.value.filter((b) => b.renewal === false)
})
function redirection(type) {
  router.push({ name: `budget-${type}` })
}
const user = computed(() => $store.value.getters.user)
const drawer = computed(() => $store.value.getters.drawer)

const setDrawer = (v) => $store.value.commit('setDrawer', v)
const hasPermission = $store.value.getters.hasPermission
function reset() {
  $store.value.commit('resetsNewBudgets')
}
</script>
