<template>
  <div>
    <NotificationSnackbar />

    <!-- navbar -->
    <AppBar ref="appBar" :title="title" style="min-width: 360px" />

    <!-- menu -->
    <NavBar />
    <!-- page -->
    <v-main>
      <router-view
        @open-recommendation-group-editor="
          openRecommendationGroupEditor($event)
        "
        @open-tile-editor="openTileEditor"
        @open-dashboard-editor="openDashboardEditor"
      />
    </v-main>

    <!-- recommendation group editor -->
    <v-dialog
      :key="recommendationGroupEditorKey"
      v-model="recommendationGroupEditorDialog"
      fullscreen
    >
      <recommendation-group-editor
        @close="closeRecommendationGroupEditor"
      ></recommendation-group-editor>
    </v-dialog>

    <!-- dashboard editor -->
    <v-dialog v-model="dashboardEditorDialog" fullscreen>
      <dashboard-editor
        @close="dashboardEditorDialog = false"
      ></dashboard-editor>
    </v-dialog>

    <!-- tile editor -->
    <v-dialog v-model="tileEditorDialog" fullscreen>
      <tile-editor :key="tileEditorKey" @close="closeTileEditor"></tile-editor>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router/composables'

import AppBar from '@/components/layouts/AppBar.vue'
import NavBar from '@/components/layouts/NavBar.vue'
import TileEditor from '@/components/tile/TileEditor'
import DashboardEditor from '@/components/dashboard/NewDashboard'
import RecommendationGroupEditor from '@/components/recommendations/RecommendationGroupEditor'
import NotificationSnackbar from '@/components/shared/NotificationSnackbar.vue'

const instance = getCurrentInstance().proxy
const store = computed(() => instance.$store)
const $lang = computed(() => instance.$lang)
const route = useRoute()

const recommendationGroupEditorDialog = ref(false)
const recommendationGroupEditorKey = ref(0)
const tileEditorDialog = ref(false)
const dashboardEditorDialog = ref(false)
const callback = ref(async () => {})
const tileEditorKey = ref(0)

const title = computed(() => {
  return $lang.value.menu[route.name] || ''
})

function openRecommendationGroupEditor(cb) {
  callback.value = cb
  recommendationGroupEditorDialog.value = true
}

function closeRecommendationGroupEditor(newGroup) {
  callback.value(newGroup)
  recommendationGroupEditorDialog.value = false
}

function openTileEditor(cb = async () => {}) {
  tileEditorDialog.value = true
  callback.value = cb
}

function openDashboardEditor(cb = async () => {}) {
  dashboardEditorDialog.value = true
  callback.value = cb
}

async function closeTileEditor(useCallback = true) {
  if (useCallback) {
    await callback.value()
  }
  tileEditorDialog.value = false
  tileEditorKey.value += 1
}

onMounted(async () => {
  const { user } = store.value.getters
  // get scopes
  if (user.id) {
    await store.value.dispatch('getScopes')
  }
})
</script>

<style>
@import '@/assets/global.css';
</style>
