<template>
  <v-app v-if="!loading">
    <router-view></router-view>
  </v-app>
</template>

<script setup>
import { computed, getCurrentInstance, ref, watch } from 'vue'
import moment from 'moment/moment'

const instance = getCurrentInstance().proxy
const store = computed(() => instance.$store)
const lang = computed(() => instance.$lang)
const isAuth = computed(() => store.value.getters.isAuth)
const userLang = computed(() => store.value.getters.user.lang)
const loading = ref(false)

watch(
  isAuth,
  async (isAuthenticated) => {
    if (!isAuthenticated) return
    loading.value = true
    instance.$vuetify.theme.themes.light = await store.value.dispatch(
      'getTheme',
    )
    loading.value = false
    lang.value.setLang(userLang.value)
    moment.locale(userLang.value)
  },
  { immediate: true },
)
</script>
