module.exports = {
  welcomeTitle: 'Welcome',
  welcomeSubtitle: 'What do you want to do today ?',
  pinModal: {
    title: 'Select a dimension',
    subText:
      'Your dimension will be selected by default the next time you log in.',
    noDataTitle: 'No dimensions found.',
    noDataSubTitle: 'Please contact your administrator',
  },
  editUserModal: {
    title: 'My Profile',
    subTitle: 'Configure your user',
    gravatar: 'Customize my gravatar',
    inputFirstName: 'First Name',
    inputLastName: 'Last Name',
    inputEmail: 'Email',
    selectLangue: 'Language',
  },
  editLogoModal: {
    tooltip: 'Add your logo',
  },
  dimension: {
    pin: 'Pinned',
  },
  granularity: {
    lastThirtyDay: 'The last 30 days',
    lastMonth: 'The last month',
    thisMonth: 'This month',
    startYear: 'Start of the year',
    label: 'Period',
  },
  charts: {
    btn: 'Select a dimension',
    noData: 'No supplier is selected.',
    values: 'Values',
  },
  kpi: {
    lastThirtyDay: 'Last 30 days',
    lastMonth: 'Last month',
    thisMonth: 'This month',
    startYear: 'This year',
  },
  sectionsTitles: {
    expensesBySupplierTitle: 'Expenses by provider',
    expensesBySupplierSubTitle: 'The amounts are displayed in',
    expensesBySupplierSeparator: 'and by',
    expensesBySupplierDay: 'DAYS',
    expensesBySupplierMonth: 'MONTH',
    dimensions: 'Sub-dimensions of',
  },
  childDimension: {
    cost: 'Total cost',
    budget: 'Add budget',
    search: 'Search dimension',
    filterTitle: 'Filter dimensions',
    filterSelectedTitleSingular: 'Selected filter',
    filterSelectedTitlePlural: 'Selected filters',
    filterOptionsOne: 'Recently viewed',
    filterOptionsTwo: 'Ascending cost',
    filterOptionsThree: 'Descending cost',
    noChild: 'No child dimensions',
    noResult: 'No result found',
    trend: 'Trend',
    granularityInfo: 'Compared to the previous period',
  },
}
