module.exports = {
  apiKey: 'API key',
  apiSecret: 'API secret',
  back: 'Back',
  beaconActivation: 'Beacon activation',
  bucketName: 'Bucket name',
  bucketRegion: 'Region',
  categories: 'Categories',
  category: 'Category',
  company_address: 'Company address',
  company_billing_address: 'Billing address',
  company_name: 'Company name',
  company_phone: 'Phone number',
  connectorName: 'Connector name',
  connectorReport: 'Report name',
  country: 'Country',
  currency: 'Currency',
  dateRange: 'Date range',
  email: 'Primary email address',
  email_confirmation: 'Email confirmation',
  endingDate: 'Ending date',
  filters: 'Filters',
  firstname: 'Firstname',
  from: 'from',
  headers: 'Headers',
  language: 'Interface Language',
  days: 'days',
  last14days: 'Last 14 days',
  last30days: 'Last 30 days',
  last60days: 'Last 60 days',
  last7days: 'Last 7 days',
  last90days: 'Last 90 days',
  lastMonth: 'The last month',
  lastname: 'Lastname',
  mailConfirmationCode: 'Activation code',
  month: 'Month',
  monthHint: 'Search a month',
  option: 'Option',
  password: 'Password',
  passwordRecovery:
    'Please enter your mail address. We will send you a link to change your password.',
  passwordRecoveryTitle: 'Password recovery',
  passwordUpdateNewPass: 'New password : ',
  password_confirmation: 'Password confirmation',
  period: 'Period',
  product: 'Product',
  products: 'Products',
  provider: 'Provider',
  providerHint: 'Search a specific provider',
  providers: 'Providers',
  region: 'Region',
  usageAccountId: 'Account ID',
  reportName: 'Report name',
  resetFilters: 'Reset all filters',
  searchConnector: 'Search a connector',
  search_a_user: 'Search a user',
  startingDate: 'Starting date',
  themeDark: 'Dark mode',
  thisMonth: 'Current month',
  thisWeek: 'This week',
  to: 'to',
  year: 'Year',
  yearHint: 'Search a year',
  groupBy_resourceId: 'Resource',
  groupBy_product: 'Product',
  groupBy_category: 'Category',
  groupBy_region: 'Region',
  groupBy_usageAccountId: 'Account ID',
  groupBy: 'Group by',
  resourceId: 'Resource ID',
  offer: 'Offer',
  searchByProvider: 'Search by provider',
  welcomeLogin: 'Veuillez vous connecter pour accéder à votre espace.',
  resetPassword: 'Reset your password',
  betaMode: 'Beta mode',
  loginError: 'Login error, please verify your credentials.',
}
