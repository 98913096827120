export async function useSessionClear() {
  const lang = localStorage.getItem('lang')
  const help = localStorage.getItem('help')

  // reset local storage
  localStorage.clear()

  // reload previous settings
  localStorage.setItem('lang', lang)
  localStorage.setItem('help', help)
  localStorage.setItem('darkMode', 'false')
  window.location.reload()
}
