import { guestRoutes } from '@/routes/roleRoutes/guest'
import { adminRoutes } from '@/routes/roleRoutes/admin'
import { viewerRoutes } from '@/routes/roleRoutes/viewer'
import { userRoutes } from '@/routes/roleRoutes/user'

export const routeStrategy = {
  admin: {
    layout: () => import('@/layouts/MainLayout.vue'),
    routes: adminRoutes,
  },
  user: {
    layout: () => import('@/layouts/MainLayout.vue'),
    routes: userRoutes,
  },
  viewer: {
    layout: () => import('@/layouts/ViewersLayout.vue'),
    routes: viewerRoutes,
  },
  guest: {
    layout: () => import('@/layouts/DefaultLayout.vue'),
    routes: guestRoutes,
  },
}
