import { dimensionRoutes } from '@/routes/dimension'
import { settingRoutes } from '@/routes/setting'
import { budgetRoutes } from '@/routes/budget'
import { recommandationRoutes } from '@/routes/recommandation'

export const adminRoutes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        alias: '/',
        component: () => import('@/views/HomeView.vue'),
        meta: { permission: 'home.index' },
      },
      {
        path: 'custom-dashboard',
        component: () => import('@/views/Dashboards.vue'),
        meta: { permission: 'dashboard.index' },
        children: [
          {
            path: '',
            name: 'custom-dashboard-table',
            meta: { permission: 'dashboard.index' },
            component: () => import('@/components/dashboard/DashboardList.vue'),
          },
          {
            path: ':id',
            name: 'custom-dashboard-show',
            meta: { permission: 'dashboard.show' },
            component: () =>
              import('@/components/dashboard/DashboardContainer.vue'),
          },
        ],
      },
      {
        name: 'inventory',
        path: 'inventory',
        component: () => import('@/views/InventoryView.vue'),
        children: [
          {
            name: 'inventory-empty-view',
            path: '',
            component: () =>
              import('@/components/layouts/inventory/InventoryEmptyView.vue'),
          },
          {
            name: 'inventory-detail',
            path: ':id',
            component: () =>
              import('@/components/layouts/inventory/InventoryDetailView.vue'),
          },
        ],
      },
      {
        name: 'explorer',
        path: 'explorer',
        component: () => import('@/views/ExplorerView.vue'),
        meta: {
          miniNavBar: true,
          permission: 'explorer.index',
          deprecated: true,
        },
      },
      {
        name: 'explorers',
        path: 'explorers',
        component: () => import('@/views/ExplorersLayout.vue'),
        children: [
          {
            name: 'explorers-cloud',
            path: 'cloud',
            component: () => import('@/views/explorers/CloudExplorer.vue'),
            meta: { icon: 'cloud', color: 'primary' },
          },
          {
            name: 'explorers-kubernetes',
            path: 'kubernetes',
            component: () => import('@/views/explorers/KubernetesExplorer.vue'),
            meta: { icon: 'kubernetes', color: 'red' },
          },
          {
            name: 'explorers-carbon',
            path: 'carbon',
            component: () => import('@/views/explorers/CarbonExplorer.vue'),
            meta: { icon: 'molecule-co2', color: 'green' },
          },
        ],
        meta: { permission: 'explorer.index' },
      },
      {
        name: 'catalog',
        path: 'catalog',
        component: () => import('@/views/CatalogView.vue'),
        meta: { miniNavBar: true, permission: 'explorer.index' },
      },
      {
        name: 'alerts',
        path: 'alerts',
        component: () => import('@/views/Alerts.vue'),
        meta: { disabled: true, permission: 'alert.index' },
      },
      {
        name: 'alertReport',
        path: 'alert-report/:id',
        component: () => import('@/views/AlertReport.vue'),
        meta: { disabled: true, permission: 'alert.show' },
      },
      {
        name: 'tags',
        path: 'tags',
        component: () => import('@/views/TagsView.vue'),
        children: [
          {
            name: 'tags-index',
            path: '',
            redirect: 'dashboard',
            meta: { permission: 'tags-dashboard.index' },
          },
          {
            name: 'tags-dashboard',
            path: 'dashboard',
            component: () => import('@/views/TagsDashboard.vue'),
            meta: { permission: 'tags-dashboard.index' },
          },
          {
            name: 'tags-list',
            path: 'list',
            component: () => import('@/views/TagsList.vue'),
            meta: { permission: 'tags-dashboard.index' },
          },
          {
            name: 'tags-configuration',
            path: 'configuration',
            component: () => import('@/components/tags/ProvidersTagsTable.vue'),
            meta: { permission: 'tags-dashboard.index' },
          },
        ],
      },
      {
        name: 'azure',
        path: 'azure',
        component: () => import('@/views/SettingsView.vue'),
        meta: { permission: 'connector.store' },
      },
      {
        name: 'googleCloud',
        path: 'google',
        component: () => import('@/views/SettingsView.vue'),
        meta: { permission: 'connector.store' },
      },
      {
        name: 'OracleCloud',
        path: 'oracle',
        component: () => import('@/views/SettingsView.vue'),
        meta: { permission: 'connector.store' },
      },
      {
        name: 'Optimization',
        path: 'optimization',
        component: (resolve) =>
          require(['@/views/OptimizationLayout.vue'], resolve),
        children: [
          {
            name: 'optimization-overview',
            path: '',
            redirect: 'unused-resources',
          },
          {
            name: 'unused-resources',
            path: 'unused-resources',
            component: (resolve) =>
              require(['@/views/UnusedResources.vue'], resolve),
          },
          {
            name: 'oversized-resources',
            path: 'oversized-resources',
            component: (resolve) =>
              require(['@/views/OversizedResources.vue'], resolve),
          },
        ],
        meta: { role: 'user' },
      },
      {
        name: 'setPassword',
        path: 'setPassword',
        component: () => import('@/views/SetPassword.vue'),
        meta: { menu: false, navbar: false },
      },
      {
        path: '/viewer-dashboard',
        component: () => import('@/views/viewers/DashboardView.vue'),
      },
      ...dimensionRoutes,
      ...settingRoutes,
      ...budgetRoutes,
      ...recommandationRoutes,
    ],
  },
]
