module.exports = {
  form: {
    rules: {
      email: 'Entrez une adresse e-mail valide',
      emailMandatory: "L'adresse e-mail est obligatoire",
      password: 'Entrez votre mot de passe',
    },
  },
  loginError: {
    invalidCredential: 'E-mail ou mot de passe incorrect',
    invalidConnexionMethod: 'Connexion non autorisée',
    serverError: 'Un problème est survenu, merci de réessayer plus tard',
  },
  or: 'OU',
  dialog: {
    success: {
      title: 'Lien de connexion',
      content:
        'Un lien de connexion a été envoyé à votre email. Veuillez vérifier votre boîte de réception et cliquer sur le lien pour vous connecter. Ce lien expire le ',
    },
    unauthorized: {
      title: 'Accès non autorisé',
      content:
        "Vous n'êtes pas autorisé à reçevoir un lien deconnexion. Veuillez contacter votre administrateur ou le support pour plus d'informations.",
    },
  },
}
