<template>
  <v-navigation-drawer
    v-if="showNavbar"
    :value="store.getters.drawer"
    app
    style="font-family: 'Mangueira Medium', sans-serif"
    @input="(val) => store.dispatch('setDrawer', val)"
  >
    <v-img
      alt="Lota.cloud"
      class="my-4 pt-2 ma-auto"
      contain
      max-width="100"
      src="@/assets/image/lota_logo.png"
    />

    <v-list dense nav>
      <template v-for="item in menu">
        <v-list-item
          v-if="!item.dropdown && isAuthorized(item)"
          :key="item.name"
          :color="$route.meta.color || 'primary'"
          @click="$router.replace({ name: item.name })"
        >
          <v-icon class="mr-4" left small>{{ item.icon }}</v-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ $lang.menu[item.path] }}
              <v-chip
                v-if="item.deprecated"
                class="ml-2"
                color="red"
                text-color="white"
                x-small
              >
                {{ $lang.catalog.deprecated }}
              </v-chip>
            </v-list-item-title>

            <v-list-item-subtitle v-if="item.new">
              <small class="primary--text">
                {{ $lang.menu.new }}
              </small>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="item.dropdown && isAuthorized(item)"
          :key="item.name"
          :class="isGroupActive(item) ? 'active' : ''"
        >
          <template v-slot:activator>
            <v-icon class="mr-4" left small>
              {{ item.icon }}
            </v-icon>

            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                {{ $lang.menu[item.name] }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.new">
                <small :class="`${$route.meta.color || 'primary'}--text`">
                  {{ $lang.menu.new }}
                </small>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <v-list dense nav subheader>
            <v-list-item
              v-for="(item, idx) in item.dropdown"
              :key="generateKey(idx)"
              :color="$route.meta.color || 'primary'"
              :disabled="item.disabled"
              @click="$router.replace({ name: item.name })"
            >
              <v-icon class="mr-4" left small>{{ item.icon }}</v-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $lang.menu[item.name] }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.disabled">
                  <small>
                    {{ $lang.menu.notAvailable }}
                  </small>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>
      </template>
    </v-list>

    <template #append>
      <v-divider />
      <v-list dense nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon left>mdi-brightness-6</v-icon>
              {{ $lang.input.themeDark }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-switch v-model="darkMode" />
          </v-list-item-action>
        </v-list-item>

        <v-list-item href="https://lota.cloud/blog/">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon left>mdi-post-outline</v-icon>

              {{ $lang.card.ourBlog }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router/composables'

const route = useRoute()
const instance = getCurrentInstance().proxy
const store = computed(() => instance.$store)

/**
 * Toggle dark mode on start
 */
onMounted(() => {
  let theme = localStorage.getItem('darkMode')

  instance.$vuetify.theme.dark = theme === 'true'
})

const darkMode = computed({
  set() {
    localStorage.setItem('darkMode', !instance.$vuetify.theme.dark)
    instance.$vuetify.theme.dark = !instance.$vuetify.theme.dark
  },
  get() {
    return instance.$vuetify.theme.dark
  },
})

const showNavbar = computed(() => !('navbar' in route.meta))

/**
 * Check if the user is authorized to view the menu item
 */
function isAuthorized(item) {
  if (!item.permission) return true

  return store.value.getters.hasPermission(item.permission)
}

/**
 * Check if the group is active by checking if
 * the current route is in the group
 */
function isGroupActive(item) {
  return item.dropdown.some((item) => {
    return route.name === item.path
  })
}

/**
 * Menu items
 */
const items = [
  {
    name: 'dashboard',
    path: 'dashboard',
    icon: 'mdi-home-outline',
    permission: 'home.index',
  },
  {
    name: 'dimensions',
    path: 'dimensions',
    icon: 'mdi-cube-outline',
    permission: 'dimension.index',
    new: true,
  },

  {
    name: 'explorers',
    path: 'explorers',
    icon: 'mdi-magnify',
    permission: 'explorer.index',
    new: true,
    dropdown: [
      {
        name: 'explorers-cloud',
        path: 'cloud',
        icon: 'mdi-cloud',
      },
    ],
  },
  {
    name: 'budgets',
    path: 'budget-table',
    icon: 'mdi-chart-pie-outline',
    permission: 'budget.index',
  },
  {
    name: 'explorer',
    path: 'explorer',
    icon: 'mdi-magnify',
    permission: 'explorer.index',
    deprecated: true,
  },
  {
    name: 'inventory',
    path: 'inventory-empty-view',
    icon: 'mdi-format-list-bulleted',
    permission: 'inventory.index',
    new: false,
  },
  {
    name: 'optimization',
    path: 'optimization-overview',
    icon: 'mdi-piggy-bank-outline',
    permission: 'triggeredoptimizationrule.index',
    new: false,
    dropdown: [
      {
        name: 'unused-resources',
        path: 'unused-resources',
        icon: 'mdi-broom',
      },
      {
        name: 'oversized-resources',
        path: 'oversized-resources',
        icon: 'mdi-tune-vertical',
      },
    ],
  },
  {
    name: 'recommendations',
    path: 'recommendations-list',
    icon: 'mdi-gift-outline',
    permission: 'recommendation.index',
  },
  {
    name: 'tags',
    icon: 'mdi-tag-outline',
    permission: 'tags-dashboard.index',
    dropdown: [
      {
        name: 'tags-dashboard',
        path: 'tags/dashboard',
        icon: 'mdi-chart-box-outline',
      },
      {
        name: 'tags-list',
        path: 'tags/list',
        icon: 'mdi-format-list-bulleted',
      },
      {
        name: 'tags-configuration',
        path: 'tags/configuration',
        icon: 'mdi-cog-outline',
      },
    ],
  },
  //{
  //  name: 'alerts',
  //  path: 'alerts',
  //  icon: 'mdi-bell-outline',
  //  permission: 'alert.index',
  //},
  {
    name: 'settings',
    path: 'settings',
    icon: 'mdi-cog-outline',
    permission: 'setting.index',
  },
]
function generateKey(idx) {
  const timestamp = new Date().getTime()
  const index = idx * timestamp
  return index.toString()
}

const menu = computed(() => {
  return items.filter((item) => {
    return !item.beta || (item.beta && betaMode.value)
  })
})
</script>

<style scoped>
.active {
  width: 100%;
  background: rgba(34, 131, 197, 0.12);
  border-radius: 4px;
}
</style>
