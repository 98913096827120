module.exports = {
  tagKey: 'Clé',
  tagValue: 'Valeur',
  provider: 'Fournisseur',
  product: 'Produit',
  resourceId: 'ID de ressource',
  cost: 'Coût HT',
  resourceCount: 'Nombre de ressources',
  tagList: 'Liste des tags',
  noTag: 'Sans étiquette',
  noTagCost: 'Dépenses non taguées',
  tagCost: 'Dépenses taguées',
  taggedResources: 'Ressources taguées',
  noTagResources: 'Ressources non taguées',
  tagCovering: 'Couverture du tag',
  seeAllTags: 'Voir tous les tags',
  inDetail: 'en détail',
  listChartTitle: 'Répartition des dépenses',
  configuration: 'Configuration',
  enable: 'Activer',
  disable: 'Désactiver',
  availableTags: 'Étiquettes disponibles',
  enabledTags: 'Étiquettes activés',
  noEnabledTagText: "Aucun tag n'est activé pour le moment",
  noAvailableTagText: "Aucun tag n'est disponible pour le moment",
  searchPlaceHolder: 'Rechercher une étiquette (clé ou valeur)',
  loadingText: 'Chargement en cours...',
  noResultsText: 'Aucun résultat pour ',
  pageTitle: 'activer / désactiver des tags',
  updateData: 'Mettre à jour',
  dataSuccessfullyUpdated: 'Vos données ont été mises à jour !',
  noAvailableTags: 'Aucun tag disponible',
  enableSelectedTags: 'Activer les tags sélectionnés',
  disableSelectedTags: 'Désactiver les tags sélectionnés',
  caseSensitiveSearch: 'sensible à la casse',
  available: 'Tags Disponibles',
  enabled: 'Tags Activés',
  refreshDatabase: 'Rafraîchir vos données',
  dataUpToDate: 'données à jour',
  dashboardTitle: 'Synthèse',
  tagsCount: "Nombre d'étiquettes",
  taggedResourceCount: 'Ressources étiquetées',
  notTaggedResourceCount: 'Ressources non étiquetées',
  mediumCoveringRate: 'Taux de couverture moyen',
  coveringRate: 'Taux de couverture',
  top5ExpensesPerTag: 'Top 5 dépenses par étiquette',
  newTags: 'Nouvelles étiquettes',
  firstSeen: 'Apparues',
  since: 'Depuis',
  resourcesCount: 'Nombre de ressources',
  totalExpenses: 'Dépenses totales',
  appearedOn: 'Apparue le',
  tagAlerts: 'Alertes non acquittées',
  detail: "Détail de l'étiquette",
  mediumCovering: 'Couverture moyenne',
  evolutionOfExpenses: 'évolution des dépenses',
  listOfResources: 'Liste des ressources',
  listOfUsages: 'Liste des consommations',
  distribution: 'Répartition des étiquettes',
  from: 'du',
  to: 'au',
  allTags: 'Inclure toute les étiquettes',
  filteredTags: 'Inclure uniquement les étiquettes suivantes',
  taggedCost: 'Dépense étiquetées',
  notTaggedCost: 'Dépense sans étiquettes',
  coverageTarget: 'Objectif de couverture',
  averageCoverage: 'Couverture moyenne',
  coverage: 'Couverture des étiquettes',
  list: 'Liste des étiquettes',
  sumOfExpenses: 'Somme',
  coverageRate: 'Taux de remplissage',
  filters: 'Filtres',
  mustSelect: 'Vous devez sélectionner au moins un filtre.',
  noTagPeriod: "Vous n'avez pas de dépenses étiquetées sur cette période.",
  tagsConfiguration: 'Configuration des étiquettes',
  tagsUpdate: 'Mettre à jour les étiquettes',
  activateByTagKey: 'Activer les étiquettes par clé',
  autoEnableTag: 'Activer toutes les valeurs',
  noExpenses: "Cette étiquette n'a pas de dépenses sur cette période.",
  percentTagsCoverage: 'Dépenses étiquetées',
  percentTagsNotCoverage: 'Dépenses non étiquetées',
  insufficientTagsCoverage: 'Votre taux de couverture moyen est insuffisant :',
  excellentTagsCoverage: 'Votre taux de couverture moyen est excellent :',
  averageCoveringRatePerMonths: 'Taux de couverture des étiquettes par mois',
  loadingCoveringChart: 'Chargement du graphique de couverture en cours...',
  internalTags: 'Étiquettes internes',
  providersTags: 'Étiquettes fournisseur',
  strategies: 'Stratégies',
  dateUndefined: 'Non définie',
  strategyDeleted: 'La stratégie a été supprimée avec succès.',
  strategyDeletedError:
    "Une erreur s'est produite lors de la suppression de la stratégie.",
  strategyName: 'Nom de votre stratégie',
  name: 'Nom',
  activityPeriod: 'Période d’activité',
  definePeriod:
    'Définissez une période à laquelle votre stratégie sera active.',
  strategyStart: 'Début de la stratégie',
  strategyEnd: 'Fin de la stratégie',
  noPeriod:
    ' Si vous ne définissez pas de période, votre stratégie sera active en permanence.',
  selectResource: 'Veuillez selectionner au moins une une ressource',
  scope: 'filtre',
  scopeSubtitle: 'Choisissez un filtre pour cibler vos ressources.',
  resources: 'Ressources',
  resourceSubtitle:
    'Sélectionnez les ressources qui intégreront votre stratégie.',
  path: 'Chemin',
  createLotaTag: 'Créer un tag Lota',
  lotaTags: 'Vos Tags Lota',
  lotaTagsSubtitle: 'Visualisez et organisez vos étiquettes internes.',
  strategyCreate: 'Créer une stratégie',
  key: 'Clé',
  value: 'Valeur',
  tagDeleted: 'Le tag a été supprimé avec succès.',
  tagDeletedError: "Une erreur s'est produite lors de la suppression du tag.",
  strategyCreated: 'La stratégie a été créée avec succès.',
  lotaTagCreated: 'Le tag a été créé avec succès.',
  lotaTagCreate: 'Création un tag Lota',
  fieldRequired: 'Ce champ est requis.',
  tagsProvider: 'Vos tags fournisseur',
  tagsProviderSubtitle: 'Visualisez et organisez vos tags fournisseur.',
  undefinedProvider: 'Non défini',
  updateResources: 'Mettre à jour les ressources',
  fromTo: 'Stratégie active du',
  strategyFrom: 'Stratégie active à partir du',
  strategyTo: 'Stratégie active jusqu’au',
  strategyPermanent: 'Stratégie active en permanence',
  updatedResources: 'Vos ressources ont été mises à jour avec succès.',
  tagManagement: 'Gestionnaire de Tags',
  tagManagementSubtitle:
    'Créez, organisez vos tags et élaborez des stratégies.',
  goFurther: 'Aller plus loin?',
  goFurtherSubtitle:
    'Consultez notre guide des bonnes pratiques pour en savoir plus sur sur la gestion de vos étiquettes.',
  errorUpdate: "Une erreur s'est produite lors de la mise à jour.",
  selectedResources: 'Ressources sélectionnées',
  resource: 'Ressource',
}
